
import CreatePasswordResetResponse from "@/models/CreatePasswordResetResponse";
import SnackbarNotification, { SnackbarNotificationType } from "@/models/SnackbarNotification";
import Routes from "@/Routes";
import AuthStore, { PasswordResetTokenExistsException } from "@/store/AuthStore";
import NotificationStore from "@/store/NotificationStore";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";

@Component({
  components: {
  },
})
export default class ForgotPasswordPage extends Vue {
  loading = false;
  recoveryEmail = "";

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => 
        !!v || 
        this.$t('generic.validation.required'),
      email: (v: any) => 
        /.+@.+/.test(v) || 
        this.$t('generic.validation.validEmail'),
    }
  }
  recoveryEmailSentSuccessfully = false;
  passwordResetTokenCreated = false;
  passwordResetTokenAlreadyExists = false;
  recoveryEmailResentSuccessfully = false;  

  get showRecoveryEmailForm(): boolean {
    return !this.recoveryEmailSentSuccessfully && !this.passwordResetTokenAlreadyExists && !this.passwordResetTokenCreated;
  }

  get signinRoute(): RouteConfig {
    return Routes.signin;
  }

  async onSendRecoveryEmailClick() {
    if (!(this.$refs.forgotPasswordForm as any).validate()) {
      return;
    }
    this.loading = true;

    try {
      const response = await AuthStore.sendPasswordRecoveryEmail(this.recoveryEmail);
      if (response instanceof CreatePasswordResetResponse) {
        this.handleCreatePasswordResetResponse(response);
      } else if (response instanceof PasswordResetTokenExistsException) {
        this.handlePasswordResetTokenExistsException();
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      const message = this.$t("views.forgotPassword.form.submit.error.notification").toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    }
    
    this.loading = false;
  }

  async resendRecoveryEmail() {
    this.loading = true;

    try {
      var success = await AuthStore.resendPasswordRecoveryEmail(this.recoveryEmail);

      if (success) {
        this.recoveryEmailResentSuccessfully = true;
      } else {
        throw new Error();
      }
    } catch (e) {
      const message = this.$t("views.forgotPassword.form.submit.error.notification").toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    }
    
    this.loading = false;
  }

  handleCreatePasswordResetResponse(response: CreatePasswordResetResponse) {
    if (response.passwordResetCreated && response.emailSent) {
      this.recoveryEmailSentSuccessfully = true;
      this.passwordResetTokenCreated = true;      
    } else if (response.passwordResetCreated && !response.emailSent) {
      this.passwordResetTokenCreated = true;
      const message = this.$t("views.forgotPassword.form.submit.error.notification").toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    } else {
      throw new Error();
    }
  }
  handlePasswordResetTokenExistsException() {
    this.passwordResetTokenAlreadyExists = true;
  }
}
